import React from "react"
import Layout from "../../components/layout"
import CommonProduct from "../../components/common/CommonProduct"
import image from "../../../static/Client/FaceApp.png"
import { IoFastFoodOutline } from "react-icons/io5"
import { MdOutlineFaceRetouchingNatural, MdPhoneIphone } from "react-icons/md"
import { FaRegCalendarCheck, FaShieldAlt } from "react-icons/fa"
import { BiSync } from "react-icons/bi"
const Facenet = () => {
  const data = {
    name: "Face Net",
    description:
      "Face Net is an advanced mobile application that leverages cutting-edge machine learning technology for seamless facial recognition. Designed to enhance workforce management, it automatically captures employee clock-ins and clock-outs through face detection. Integrated with the HRMS dashboard, Face Net ensures accurate attendance tracking, enhances security, and simplifies employee management.",
    image: image,
    features: [
      {
        id: 1,
        icon: <MdOutlineFaceRetouchingNatural />,
        title: "AI-Powered Facial Recognition",
        description:
          "Utilizing advanced machine learning algorithms, Face Net offers precise facial recognition to automate attendance. Employees can effortlessly clock in and out using their face, ensuring accuracy, eliminating proxy attendance, and boosting security.",
      },
      {
        id: 2,
        icon: <FaRegCalendarCheck />,
        title: "Real-Time Attendance Tracking",
        description:
          "Capture clock-in and clock-out data instantly with real-time synchronization to the HRMS dashboard. This ensures that attendance records are always up-to-date and accessible for HR teams, reducing manual errors and administrative tasks.",
      },
      {
        id: 3,
        icon: <BiSync />,
        title: "Seamless HRMS Integration",
        description:
          "Face Net seamlessly integrates with the HRMS dashboard, allowing HR managers to view detailed attendance reports, generate timesheets, and analyze employee punctuality. This integration streamlines HR processes and improves overall workforce management.",
      },
      {
        id: 4,
        icon: <FaShieldAlt />,
        title: "Enhanced Security & Privacy",
        description:
          "Our platform prioritizes security by ensuring that facial data is encrypted and securely stored. Face Net complies with global data protection regulations, guaranteeing the privacy and confidentiality of employee information.",
      },
      {
        id: 5,
        icon: <MdPhoneIphone />,
        title: "User-Friendly Mobile App",
        description:
          "Designed with ease of use in mind, Face Net offers an intuitive mobile interface for employees to clock in and out with just a glance. The app is compatible with various devices, ensuring convenience and accessibility for all users.",
      },
    ],
  }

  return (
    <Layout>
      <CommonProduct
        name={data.name}
        description={data.description}
        image={data.image}
        features={data.features}
      />
    </Layout>
  )
}

export default Facenet
